import { DashboardStore } from "./dashboard.store";
import { HttpClient } from "@angular/common/http";

import { LoginService } from "./../login/login.service";
import { Intercom } from "@supy-io/ngx-intercom";
import {
  Injectable,
  AfterViewInit,
  signal,
  WritableSignal,
  computed,
  Signal,
  effect,
} from "@angular/core";
import { Router } from "@angular/router";

import { LocalStorageService } from "src/app/utils/localstorage.service";
import { environment } from "../../environments/environment";
import { Observable, Subject } from "rxjs";
import { ConversationsService } from "./conversations/conversations.service";
import * as _ from "underscore";
import { AccountantService } from "./main/accountant/accountant.service";
import { Title } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class DashboardService {
  show_loading = new Subject<any>();

  clientsLoaded = new Subject<any>();
  conversationsLoaded = new Subject<any>();

  dispatchNewMessage = new Subject<any>();
  dispatchNewSocketMessage = new Subject<any>();
  newMessage = new Subject<any>();
  getOpenTrackedItemsCount = new Subject<any>();

  openDeleteTrackedItemModal = new Subject<any>();
  closeJobDrawer$ = new Subject<any>();

  // New subects for badges
  updateTrackedItems = new Subject();
  updateReadStatus = new Subject();

  unread_conversations: number = 0;
  opened_tracked_items: number = 0;
  total_tracked_items: number = 0;

  paymentDone = new Subject();
  startTimeTracking = new Subject();

  public clients = [];
  public conversations = [];

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private conversationsService: ConversationsService,
    private intercom: Intercom,
    private accountantService: AccountantService,
    private titleService: Title,

    public dashboardStore: DashboardStore,
  ) {
    console.log("DashboardService loaded");

    this.dashboardStore.clientsLoaded.subscribe((res: any) => {
      this.show_loading.next(false);
      this.conversationsService.updateOpenTrackedItemsBadge(
        this.dashboardStore.clients,
      );
      this.updateOpenTrackedItemsBadge();

      this.intercom.update({
        app_id: "wqv1cgow",
        name: `${this.localStorage.get("user")["first_name"]} ${
          this.localStorage.get("user")["last_name"]
        }`,
        email: this.localStorage.get("user")["email"],
        user_hash: this.localStorage.get("user")["user_hash"],
        custom_data: {
          client_companies: this.dashboardStore.clients.length,
        },
      });
    });

    // Triggered when new client is added
    this.accountantService.clients.subscribe((sub) => {
      if (sub.company != undefined) {
        this.dashboardStore.clients.push(sub.company);

        // Fixes bug that when a new client is added
        // the conversation subscription was not being made
        this.dashboardStore.clientsLoaded.next(null);
      }
    });
    effect(() => {
      console.log("new value", this.filters());
      this.localStorage.set("dashboardsFilters", this.filters());
    });
  }
  filters: WritableSignal<any> = signal(
    this.localStorage.get("dashboardsFilters") || {},
  );
  // activeDashboard: WritableSignal<string> = signal("");
  // filters = computed(() => {
  //   const defaultFilters = this.localStorage.get("dashboardsFilters");
  //   if (defaultFilters) return defaultFilters[this.activeDashboard()];
  //   return null;
  // });

  // setDefaultFilters(filters) {
  //   this.localStorage.set("dashboardFilters", {
  //     ...this.filters(),
  //     [this.activeDashboard()]: filters,
  //   });
  //   console.log(
  //     `Default filters set for dashboard ${this.activeDashboard()}. Values: ${filters} `,
  //   );
  // }

  timeTrackingStatus() {
    const url = `${environment.apiUrl}/jobs/getTimeTrackingStatus`;
    return this.http.get(url);
  }

  get_apps(): Promise<any> {
    return this.http.get(`${environment.apiUrl}/apps/`).toPromise();
  }

  get_resources(): Promise<any> {
    return this.http.get(`${environment.apiUrl}/resources/`).toPromise();
  }

  get_client_apps(): Promise<any> {
    return this.http.get(`${environment.apiUrl}/apps/`).toPromise();
  }

  get_open_tracked_items_count(): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/conversations/tracked-items.count`)
      .toPromise();
  }

  updateUnreadMessagesCount(): void {
    var linkElements = document.querySelectorAll("link[ rel ~= 'icon' i]");

    if (this.dashboardStore.unreadConversationsCount() > 0) {
      this.titleService.setTitle(
        `(${this.dashboardStore.unreadConversationsCount}) Client Hub: All-in-one Workspace where accounting professionals and clients get work done`,
      );

      linkElements[0]["href"] = "assets/img/favicon-messages.png";
    } else {
      this.titleService.setTitle(
        `Client Hub: All-in-one Workspace where accounting professionals and clients get work done`,
      );

      linkElements[0]["href"] = "assets/img/favicon.png";
    }
  }

  updateOpenTrackedItemsBadge(): void {
    let opens = 0;
    this.conversations.forEach((c) => {
      opens =
        opens +
        c.tracked_items.filter((t) => {
          return t.is_open;
        }).length;
    });

    this.opened_tracked_items = opens;
  }

  isEmailAvailable() {
    return this.http.get(`${environment.apiUrl}/company/email`).toPromise();
  }

  isHuddleAvailable() {
    return Promise.resolve(false);
    // return this.http.get(`${environment.apiUrl}/company/huddle`).toPromise();
  }

  isBooksReviewAvailable() {
    return this.http
      .get(`${environment.apiUrl}/company/books-review`)
      .toPromise();
  }
}
